import { Box, Button, Typography } from "@mui/material";
import React from "react";

export default function StartScreen({newGame, loading}) {
  return (
    <Box minHeight={500} component='div' display='flex' justifyContent='center' alignItems='center'>
      <Box margin='auto' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        <Typography variant='h4' display='flex' color='#BC562F' sx={{fontFamily: '"Righteous", cursive', mb: 2}}>
          Test Your Knowledge!
        </Typography>
        {loading
        ? <Button variant='contained'>Loading...</Button>
        : <Button variant='contained' onClick={newGame}>Start Game</Button>}
      </Box>
    </Box>
  );
}