import GridCopy from "./GridCopy";

export default function CheckSolutions(puzzle) {
  let solutions = 0;
  RemoveNumbers();
  return puzzle;

  function GetNoneEmpty() {
    const nonEmpty = [];

    for (let i = 0; i < 9; i++) {
      for (let j = 0; j < 9; j++) {
        if (puzzle[i][j] !== 0) {
          nonEmpty.push([i, j]);
        }
      }
    }

    return nonEmpty.sort(() => Math.random() - 0.5);
  }

  function CheckSolutions(grid, index) {
    if(index >= 0) {
      let row = Math.floor(index / 9);
      let col = index % 9;
      for(let n  = 1; n < 10; n++) {
        if(ValidLocation(grid, row, col, n)) {
          grid[row][col] = n;
          if(CheckSolutions(grid, FindEmpty(grid))) {
            return true;
          }
          grid[row][col] = 0;
        }
      }
      return false;
    } else {
      solutions += 1;
      return false;
    }
  }

  function ValidLocation(grid, row, col, n) {
    return (unUsedInRow(grid, row, n) && unUsedInCol(grid, col, n) && unUsedInBox(grid, row - row % 3, col - col % 3, n))
  }

  function unUsedInCol(grid, col, num) {
    for(let i = 0; i < 9; i++) {
      if(grid[i][col] === num) {
        return false;
      }
    }

    return true;
  }

  function unUsedInRow(grid, row, num) {
    for(let i = 0; i < 9; i++) {
      if(grid[row][i] === num) {
        return false;
      }
    }

    return true;
  }

  function unUsedInBox(grid, row, col, num) {
    for(let i = 0; i < 3; i++) {
      for(let j = 0; j < 3; j++) {
        if(grid[row+i][col+j] === num) {
          return false;
        }
      }
    }

    return true;
  }

  function FindEmpty(grid) {
    for(let i = 0; i < 81; i++) {
      let row = Math.floor(i / 9);
      let col = i % 9;
      if(grid[row][col] === 0) {
        return i;
      }
    }

    return -1;
  }

  function RemoveNumbers() {
    const nonEmpty = GetNoneEmpty();
    let nonEmptyCount = nonEmpty.length;
    let rounds = 3;

    while(rounds > 0 && nonEmptyCount > 17) {
      let [row, col] = nonEmpty.pop();
      nonEmptyCount -= 1;
      let removedSquare = puzzle[row][col];
      puzzle[row][col] = 0;
      solutions = 0;
      let puzCopy = GridCopy(puzzle);
      CheckSolutions(puzCopy, FindEmpty(puzCopy));
      if(solutions > 1)
      {
        puzzle[row][col] = removedSquare;
        nonEmptyCount += 1;
        rounds -= 1;
      }
    }
  }
}