import { Collapse, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function SubMenuItems({ items, isOpen }) {
  return (
    <Collapse in={isOpen} timeout='auto' unmountOnExit>
      <List component='div' disablePadding>
        {items.map((item, index) => (
          <ListItem key={index} sx={{ background: '#1F304C' }}>
            <ListItemButton component={Link} to={item.path}>
              <ListItemText primary={item.title} primaryTypographyProps={{ variant: 'subtitle2' }} sx={{ color: '#FFFFFF' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Collapse>
  );
}