import React from 'react';
import { navItems } from './navItem';
import MenuItems from './MenuItems';
import { AppBar, Typography, Box, Toolbar, SwipeableDrawer, Avatar } from '@mui/material/'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { Link, useLocation } from 'react-router-dom';

export default function Navigation() {
  const [menuState, setMenuState] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    setMenuState(false);
  }, [location]);

  function OpenMenu() {
    setMenuState(prev => !prev);
  }

  return (
    <AppBar position='sticky' sx={{ background: '#1F304C' }}>
      <Toolbar>
        <Avatar sx={{bgcolor: '#BC562F', mr: 1}}>
          AB
        </Avatar>
        <Typography variant='h4' component='div' sx={{ flexGrow: 1, fontFamily: "'Righteous', cursive" }}>
          <Link to='/' className='headerLink'>Alexander Burrows</Link>
        </Typography>
        <Box>
          <IconButton color='inherit' onClick={OpenMenu}>
            <MenuIcon />
          </IconButton>
          <SwipeableDrawer anchor='right' open={menuState} onOpen={OpenMenu} onClose={OpenMenu}>
            <MenuItems items={navItems} clickFun={OpenMenu} />
          </SwipeableDrawer>
        </Box>
      </Toolbar>
    </AppBar>
  );
}