import { Button, Typography } from "@mui/material";
import React from "react";

export default function QuizButton({isOver, newGame, chkAnswers, isLoading, score}) {
  function ButtonMaker() {
    if(isOver && isLoading) {
      return(
        <Button variant='contained'>Loading...</Button>
      );
    } else if(isOver) {
      return(
        <>
          <Typography variant='h6' sx={{mr: 2}}>Your final score is {score}/5</Typography>
          <Button variant='contained' onClick={newGame}>Start New Game</Button>
        </>
      );
    } else {
      return(
        <Button variant='contained' onClick={chkAnswers}>Check Answers</Button>
      );
    }
  };

  return (
    ButtonMaker()
  );
}