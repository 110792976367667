import React from "react";
import { Container, Paper } from "@mui/material";
import CreatePuzzle from "./helpers/CreatePuzzle";
import StartPage from "./StartPage";
import SudokuPuzzle from "./SudokuPuzzle";

export default function Sudoku() {
  const [sudokuPuzzle, setSudokuPuzzle] = React.useState([]);
  const [isStarted, setIsStarted] = React.useState(false);
  const [curTile, setCurTile] = React.useState([]);
  const [numUsedCount, setNumUsedCount] = React.useState([]);
  const [hasWon, setHasWon] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener('keydown', KeyDownHandler, false);
    return () => {window.removeEventListener('keydown', KeyDownHandler, false)}
  });

  React.useEffect(() => {
    document.title = 'Sudoku';
  }, []);

  function ChangeTile(row, col) {
    setCurTile([row, col]);
    let puzzle = {...sudokuPuzzle};
    let num = puzzle[row][col].guess;
    let gameWon = true;
    let boxRowStart = Math.floor(parseInt(row) / 3) * 3;
    let boxColStart = Math.floor(parseInt(col) / 3) * 3;

    for(let i = 0; i < 9; i++) {
      for(let j = 0; j < 9; j++) {
        if(puzzle[i][j].guess !== '' && puzzle[i][j].guess !== puzzle[i][j].value) {
          puzzle[i][j].txColor = '#E53217';
        } else if(num === puzzle[i][j].guess) {
          puzzle[i][j].txColor = '#1764E5';
        } else {
          puzzle[i][j].txColor = '#000000';
        }

        if(puzzle[i][j].guess === '' || puzzle[i][j].guess !== puzzle[i][j].value || !gameWon) {
          gameWon = false;
        }
        puzzle[i][j].bgColor = (i === parseInt(row) || j === parseInt(col)) ? '#C2C2C2' : '#FFFFFF';
        puzzle[i][j].bgColor = (i >= boxRowStart && i < boxRowStart + 3 && j >= boxColStart && j < boxColStart + 3) ? '#C2C2C2' : puzzle[i][j].bgColor;
      }
    }

    setSudokuPuzzle(puzzle);
    if(gameWon !== hasWon) {
      setHasWon(gameWon);
    }
  }

  function UpdateUsedNumCount(n) {
    const newUsed = [...numUsedCount];
    newUsed[n]++;
    setNumUsedCount(newUsed);
  }

  function InitUsedNums(puzzle) {
    const newUsed = Array(10).fill(0);
    for(let i = 0; i < 9; i++) {
      for(let j = 0; j < 9; j++) {
        if(puzzle[i][j].guess === puzzle[i][j].value) {
          newUsed[puzzle[i][j].guess]++;
        }
      }
    }
    setNumUsedCount(newUsed);
  }

  function KeyDownHandler(e) {
    if(isFinite(e.key) && e.key !== ' ') {
      UpdateNum(parseInt(e.key));
    }
  }

  function UpdateNum(num) {
    const puzzle = {...sudokuPuzzle};
    if(numUsedCount[num] > 8) {
      return;
    }
    if(!puzzle[curTile[0]][curTile[1]].isLocked) {
      puzzle[curTile[0]][curTile[1]].guess = num;
      if(puzzle[curTile[0]][curTile[1]].guess !== puzzle[curTile[0]][curTile[1]].value) {
        puzzle[curTile[0]][curTile[1]].txColor = '#E53217';
      } else {
        UpdateUsedNumCount(num);
      }
      setSudokuPuzzle(puzzle);
      ChangeTile(curTile[0], curTile[1]);
    }
  }

  function StartPuzzle() {
    const puzzle = CreatePuzzle();
    setSudokuPuzzle(puzzle);
    InitUsedNums(puzzle);
    setHasWon(false);
    setIsStarted(true);
  }

  return (
    <Container maxWidth='xl' sx={{mt: 2}}>
      <Paper elevation={3}>
        {isStarted
        ? <SudokuPuzzle puzzle={sudokuPuzzle} setTile={ChangeTile} updateNum={UpdateNum} usedNums={numUsedCount} hasWon={hasWon} startGame={StartPuzzle} />
        : <StartPage startGame={StartPuzzle} />}
      </Paper>
    </Container>
  );
}