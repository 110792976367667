export const navItems = [
    {
        title: 'Home',
        path: '/'
    },
    {
        title: 'Games',
        subMenu: [
            {
                title: 'Quiz',
                path: '/quiz'
            },
            {
                title: 'Sudoku',
                path: '/sudoku'
            }
        ]
    },
    // {
    //     title: 'Geek Stuff',
    //     subMenu: [
    //         {
    //             title: 'Pokemon Type Chart',
    //             path: '/typechart'
    //         },
    //     ]
    // },
    {
      'title': 'Patch Notes',
      'path': '/patchnotes'
    }
];