import { Box, Typography, Button } from "@mui/material";
import React from "react";

export default function SudokuPuzzle({puzzle, setTile, usedNums, updateNum, hasWon, startGame}) {
  function GetRow(row, index) {
    return(
      <React.Fragment key={index}>
        <Box variant='div' width='100%'>
          {Object.keys(puzzle[row]).map((col, coli) => (
            <Box
              variant='div'
              height={40}
              width={40}
              display='inline-flex'
              justifyContent='center'
              alignItems='center'
              borderBottom={puzzle[row][col].bBorder}
              borderRight={puzzle[row][col].rBorder}
              key={puzzle[row][col].key}
              backgroundColor={puzzle[row][col].bgColor}
              sx={{cursor: 'pointer', ":hover": {backgroundColor: '#00A1B3'}}}
              onClick={() => setTile(row, col)}
            >
              <Typography fontWeight={700} color={puzzle[row][col].txColor}>
                {puzzle[row][col].guess || '\u00A0' }
              </Typography>
            </Box>
          ))}
        </Box>
      </React.Fragment>
    );
  }

  function GetNums() {
    return (
      <React.Fragment>
        {usedNums.map((n, i) => {
          if(i === 0) {
            return null;
          }
          return (
            <Box
              variant='div'
              height={40}
              width={40}
              display='inline-flex'
              justifyContent='center'
              alignItems='center'
              key={i}
              sx={{cursor: 'pointer'}}
              borderRight={i < 9 ? '1px solid #000000' : ''}
              onClick={() => updateNum(i)}
            >
              <Typography fontWeight={700} color={usedNums[i] > 8 ? '#a2a2a2' : '#000000'}>
                {i}
              </Typography>
            </Box>
          );
        })}
      </React.Fragment>
    );
  }

  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' minHeight={500}>
      <Box variant='div' maxWidth={370} minWidth={370} maxHeight={370} minHeight={370} border='2px solid #000000'>
        {Object.keys(puzzle).map((row, index) => (
          GetRow(row, index)
        ))}
      </Box>
      <Box variant='div' maxWidth={370} minWidth={370} maxHeight={40} minHeight={40} border='1px solid #000000' sx={{mt: 2}}>
        {GetNums()}
      </Box>
      {hasWon && 
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
          <Typography sx={{mt: 2}}>You WON!!!</Typography>
          <Button variant='contained' sx={{mt: 1, mb: 2}} onClick={startGame}>Play Again</Button>
        </Box>
      }
    </Box>
  );
}