import { Container, Paper } from "@mui/material";
import React from "react";
import { nanoid } from "nanoid";
import he from 'he';
import StartScreen from "./StartScreen";
import QuestionBuilder from "./QuestionBuilder";

export default function Quiz() {
  const [isStarted, setIsStarted] = React.useState(false);
  const [isOver, setIsOver] = React.useState(false);
  const [quizData, setQuizData] = React.useState([]);
  const [score, setScore] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    document.title = 'Quiz Game';
  }, []);
  
  function MakeQuizData(dataRaw) {
    const newArray = [];
    dataRaw.map((data, index) => {
      let newObj = {
        id: nanoid(),
        answers: [],
        correct: he.decode(data.correct_answer),
        question: he.decode(data.question)
      };

      const answers = data.incorrect_answers.slice();
      if(data.type === 'multiple') {
        answers.splice(Math.floor(Math.random() * (answers.length + 1)), 0, data.correct_answer);
        answers.map((answer) => {
          newObj.answers.push({id: nanoid(), value: he.decode(answer), variant: 'answer', isPicked: false, isRight: false, isWrong: false});
          return null;
        });
      } else {
        newObj.answers.push({id: nanoid(), value: 'True', variant: 'answer', isPicked: false, isRight: false, isWrong: false});
        newObj.answers.push({id: nanoid(), value: 'False', variant: 'answer', isPicked: false, isRight: false, isWrong: false});
      }

      newArray.push(newObj);
      return null;
    });

    setQuizData(newArray);
  }

  function GameReady() {
    setIsOver(false);
    setScore(0);
    setIsStarted(true);
    setIsLoading(false);
  }

  function NewGame() {
    setIsLoading(true);
    fetch("https://opentdb.com/api.php?amount=5")
      .then(res => res.json())
      .then(res => MakeQuizData(res.results))
      .then(res => GameReady());
  }

  function TogglePick(aID, qID) {
    const newArray = quizData.slice();
    for(let i = 0; i < newArray.length; i++) {
      if(newArray[i].id === qID) {
        for(let j = 0; j < newArray[i].answers.length; j++) {
          newArray[i].answers[j] = {
            ...newArray[i].answers[j],
            isPicked: newArray[i].answers[j].id === aID ? true : false,
            variant: newArray[i].answers[j].id === aID ? 'picked' : 'answer'
          };
        }
      }
    }

    setQuizData(newArray);
  }

  function CheckAnswers() {
    const newArray = quizData.slice();
    setIsOver(true);
    setIsLoading(true);
    for(let i = 0; i < newArray.length; i++) {
      for(let j = 0; j < newArray[i].answers.length; j++) {
        let aItem = newArray[i].answers[j];
        if(aItem.value === newArray[i].correct) {
          if(aItem.isPicked) {
            setScore(prev => prev + 1);
          }
          aItem = {
            ...aItem,
            isRight: true,
            isPicked: false,
            variant: 'aRight'
          };
        } else if(aItem.isPicked) {
          aItem = {
            ...aItem,
            isPicked: false,
            isWrong: true,
            variant: 'aWrong'
          };
        }

        newArray[i].answers[j] = aItem;
      }
    }

    setQuizData(newArray);
    setIsLoading(false);
  }

  return (
    <Container maxWidth='xl' sx={{mt: 2}}>
      <Paper elevation={3}>
        {isStarted
          ? <QuestionBuilder score={score} isLoading={isLoading} isOver={isOver} quizData={quizData} togglePick={TogglePick} newGame={NewGame} chkAnswers={CheckAnswers} />
          : <StartScreen newGame={NewGame} loading={isLoading} />}
      </Paper>
    </Container>
  );
}