export default function ArrayPuzzle() {
  const n = 9;
  const sr = 3;
  const puzzle = [];

  initArray();
  fillDiag();
  fillRemaining(0, sr);
  return puzzle;
  
  function initArray() {
    for(let i = 0; i < n; i++) {
      puzzle[i] = new Array(n);
    }
  }

  function fillDiag() {
    for (let i = 0; i < n; i += sr) {
      fillBox(i, i);
    }
  }

  function fillRemaining(row, col) {
    if(col >= n && row < n - 1) {
      row += 1;
      col = 0;
    }
    if(row >= n && col >= n) {
      return true;
    }

    if(row < sr) {
      if(col < sr) {
        col = sr;
      }
    } else if (row < n - sr) {
      if(col === Math.floor(row / sr) * sr) {
        col += sr;
      }
    } else {
      if(col === n - sr) {
        row += 1;
        col = 0;
        if(row >= n) {
          return true;
        }
      }
    }

    for(var num = 1; num <= n; num++) {
      if(CheckIfSafe(row, col, num)) {
        puzzle[row][col] = num;
        if(fillRemaining(row, col+1)) {
          return true;
        }
        puzzle[row][col] = 0;
      }
    }

    return false;
  }

  function CheckIfSafe(row, col, num) {
    return (unUsedInRow(row, num) && unUsedInCol(col, num) && unUsedInBox(row - row % sr, col - col % sr, num));
  }

  function unUsedInCol(col, num) {
    for(let i = 0; i < n; i++) {
      if(puzzle[i][col] === num) {
        return false;
      }
    }

    return true;
  }

  function unUsedInRow(row, num) {
    for(let i = 0; i < n; i++) {
      if(puzzle[row][i] === num) {
        return false;
      }
    }

    return true;
  }

  function unUsedInBox(row, col, num) {
    for(let i = 0; i < sr; i++) {
      for(let j = 0; j < sr; j++) {
        if(puzzle[row+i][col+j] === num) {
          return false;
        }
      }
    }

    return true;
  }

  function fillBox(row, col) {
    var num;
    for(let i = 0; i < sr; i++) {
      for(let j = 0; j < sr; j++) {
        do {
          num = Math.floor(Math.random() * n) + 1;
        } while (!unUsedInBox(row, col, num));

        puzzle[row+i][col+j] = num;
      }
    }
  }
}