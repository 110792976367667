import { ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Navigation from './components/nav/Navigation';
import Resume from './Resume';
import Quiz from './games/quiz/Quiz';
import Sudoku from './games/sudoku/Sudoku';
import PatchNotes from './PatchNotes/PatchNotes';
import { theme } from './theme.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <Navigation />
        <Routes>
          <Route exact path="/quiz" element={<Quiz />} />
          <Route exact path="/" element={<Resume />} />
          <Route exact path="/sudoku" element={<Sudoku />} />
          <Route exact path="/patchnotes" element={<PatchNotes />} />
        </Routes>
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);