import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: ['"Karla"', 'san-serif'].join(','),
    aHeader: {
      color: '#FFFFFF',
      background: '#1F304C',
      fontSize: '2.125rem',
      lineHeight: '1.235',
      fontStyle: 'italic',
      fontFamily: '"Righteous", cursive',
      padding: '8px',
      fontWeight: '400',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px'
    },
    qHeader: {
      color: '#FFFFFF',
      background: '#1F304C',
      fontSize: '1.25rem',
      lineHeight: '1.6',
      fontStyle: 'italic',
      padding: '8px',
      fontWeight: '400',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:disabled': {
            color: '#1F304C'
          }
        }
      },
      variants: [
        {
          props: {variant: 'answer'},
          style: {
            color: '#1F304C',
            border: '1px solid #1F304C',
            fontFamily: '"Karla", san-serif',
            '&:hover': {
              background: '#00A1B3'
            }
          }
        },
        {
          props: {variant: 'picked'},
          style: {
            color: '#1F304C',
            border: '1px solid #1F304C',
            background: '#00A1B3',
            fontFamily: '"Karla", san-serif',
            '&:hover': {
              background: '#00A1B3'
            }
          }
        },
        {
          props: {variant: 'aWrong'},
          style: {
            color: '#1F304C',
            border: '1px solid #1F304C',
            background: '#F8BCBC',
            fontFamily: '"Karla", san-serif',
            '&:hover': {
              background: '#F8BCBC'
            }
          }
        },
        {
          props: {variant: 'aRight'},
          style: {
            color: '#1F304C',
            border: '1px solid #1F304C',
            background: '#94D7A2',
            fontFamily: '"Karla", san-serif',
            '&:hover': {
              background: '#94D7A2'
            }
          }
        }
      ]
    }
  }
});