import { Box } from "@mui/material";
import React from "react";
import Question from "./Question";
import QuizButton from "./QuizButton";

export default function QuestionBuilder({quizData, togglePick, isOver, newGame, chkAnswers, isLoading, score}) {
  return (
    <Box minHeight={500} display='flex' flexDirection='column'>
      {quizData.map(item => {
        return <Question qData={item} togglePick={togglePick} key={item.id} />
      })}
      <Box display='flex' justifyContent='center' sx={{my: 2}}>
        <QuizButton isOver={isOver} isLoading={isLoading} newGame={newGame} score={score} chkAnswers={chkAnswers} />
      </Box>
    </Box>
  );
}