import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Container, List, ListItem, ListItemIcon, Paper, Typography } from "@mui/material";
import { patchNotes } from "../components/patchNotes";
import { ExpandMore } from "@mui/icons-material";
import { ReactComponent as ListBullet } from "../resume/img/d20.svg";

export default function PatchNotes() {
  React.useEffect(() =>{
    document.title = 'Patch Notes';
  }, []);

  return (
    <Container maxWidth='xl' sx={{mt: 2}}>
     <Paper elevation={3} sx={{p: 2}}>
      <Typography variant="aHeader" component='div'>
        Patch Notes
      </Typography>
      {patchNotes.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMore />} sx={{'flexDirection': 'row-reverse'}} aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
            <Typography sx={{'fontWeight': 700}}>
              {item.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List component='ul' sx={{'ml': 1}}>
              {item.notes.map((note, noteI) => (
                <ListItem component='li' key={noteI}>
                  <ListItemIcon sx={{minWidth: 0, mr: 1}}><ListBullet width={16} height={16} /></ListItemIcon>
                  <Typography>{note}</Typography>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
     </Paper>
    </Container>
  );
}