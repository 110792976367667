import { Box, Button, Typography } from "@mui/material";
import React from "react";

export default function StartPage({startGame}) {
  return(
    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' minHeight={500}>
      <Typography variant="h4" color='#BC562F' sx={{fontFamily: '"Righteous", cursive', mb: 2}}>
        Sudoku
      </Typography>
      <Button variant='contained' onClick={startGame}>Play Sudoku</Button>
    </Box>
  );
}