import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { logoData } from "./resumeLogoData.js";

export default function LogoArea() {
  return(
    <Grid container spacing={2} sx={{mb: 2, pb: 2}}>
      {logoData.map((logo, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Paper elevation={3}>
            <Box component='div' sx={{
              bgcolor: '#1F304C',
              color: '#FFFFFF',
              p: 1,
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10
            }}>
              <Box component='img' alt={logo.alt} sx={{height: 32, width: 32, p: 1, mr: 2, borderRadius: 2, bgcolor: '#FFFFFF'}} src={logo.svg}></Box>
              <Typography variant="h5">{logo.title}</Typography>
            </Box>
            <Box sx={{p: 1}}>
              <Typography>{logo.blurb}</Typography>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}