import ArrayPuzzle from "./ArrayPuzzle";
import { nanoid } from "nanoid";
import CheckSolutions from "./CheckSolutions";
import GridCopy from "./GridCopy";

export default function CreatePuzzle() {
  const puzzle = ArrayPuzzle();
  let unsolvedPuzzle = GridCopy(puzzle);

  unsolvedPuzzle = CheckSolutions(unsolvedPuzzle);

  let sudoku = {};

  for(let i = 0; i < 9; i++) {
    for(let j = 0; j < 9; j++) {
      sudoku[i] = { ...sudoku[i],
        [j]: {
          'key': nanoid(),
          'value': puzzle[i][j],
          'isLocked': false,
          'bgColor': '#FFFFFF',
          'txColor': '#000000',
          'bBorder': 'none',
          'rBorder': 'none',
          'guess': unsolvedPuzzle[i][j] === 0 ? '' : unsolvedPuzzle[i][j]
        }
      };
      
      if(j < 8) {
        sudoku[i][j].rBorder = (j + 1) % 3 === 0 ? '2px solid #000000' : '1px solid #000000';
      }
      if(i < 8) {
        sudoku[i][j].bBorder = (i + 1) % 3 === 0 ? '2px solid #000000' : '1px solid #000000';
      }
    }
  }

  return sudoku;
}