import React from "react";
import SubMenuItems from './SubMenuItems';
import { Box, List, ListItem, ListItemText, ListItemButton, Divider } from '@mui/material/';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function MenuItems({ items, clickFun }) {
  const [openIndex, setOpenIndex] = React.useState("");

  function HandleCollapse(index) {
    setOpenIndex(prevState => prevState !== index ? index : "");
  }

  function WhichListItem(item, index) {
    if(item.subMenu) {
      return (
        <ListItemButton>
          <ListItemText primary={item.title} primaryTypographyProps={{variant: 'button'}} />
          {openIndex === index ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      );
    } else {
      return(
        <ListItemButton component={Link} to={item.path}>
          <ListItemText primary={item.title} primaryTypographyProps={{variant: 'button'}} />
        </ListItemButton>
      );
    }
  }

  return (
    <Box sx={{ width: 250 }} role='presentation'>
      <List component='div' disablePadding>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem key={index} onClick={() => HandleCollapse(index)}>
              {WhichListItem(item, index)}
            </ListItem>
            <Divider />
            {item.subMenu && <SubMenuItems items={item.subMenu} isOpen={openIndex === index} />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
}