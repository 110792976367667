import { Typography, Box, Button, ButtonGroup } from "@mui/material";
import React from "react";

export default function Question({qData, togglePick}) {
  return(
    <Box>
      <Box sx={{mt: 1}}>
        <Typography textAlign='center' variant='qHeader' display='flex'>
          {qData.question}
        </Typography>
      </Box>
      <ButtonGroup sx={{justifyContent: 'stretch', display: 'flex', padding: '0 15px', mt: 1}}>
        {qData.answers.map(item => (
          <Button onClick={() => togglePick(item.id, qData.id)} key={item.id} variant={item.variant} sx={{flex: 1}}>
            {item.value}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
}