export const patchNotes = [
  {
    'title': '2024/09/21',
    'notes': [
      'Update the about me blurb.',
      'Added some more info to some of my experiences at my places of emplyment.'
    ]
  },
  {
    'title': '2024/09/16',
    'notes': [
      'Removed the Quiz game link as the API that made it posible no longer works.',
      'Updated some text on the front page to better outline my experience with different technologies.'
    ]
  },
  {
    'title': '2022/09/22',
    'notes': [
      'Titles now update when changing pages.'
    ]
  },
  {
    'title': '2022/09/13',
    'notes': [
      'Changed the layout of the quote puzzle to be more appealing.',
      'Added keyboard support so you don\'t have to use the popup keyboard.'
    ]
  },
  {
    'title': '2022/09/12',
    'notes': [
      'Fixed a few spelling and grammar errors.',
      'Fixed an issue that could cause sudoku numbers to miss count and stop you from being able to complete puzzles.'
    ]
  },
  {
    'title': '2022/09/05',
    'notes': [
      'Updated colors for sudoku so they were not so harsh.',
      'Added a Play Again button after beating sudoku.',
      'Fixed a bug in a variable initializer which could cause it to have tainted data under certain circumstances.',
      'Updated the page title so it would stop saying React App.'
    ]
  },
  {
    'title': '2022/09/04',
    'notes': [
      'Made my name link back to the home page.',
      'Added Linked In logo that links to my Linked In profile.',
      'Updated nav to remove links that are coming soon.',
      'Update Quote game to have a reset board button.',
      'Added the Linked In svg logo.',
      'Added some CSS to make my name not look like a link.'
    ]
  },
  {
    'title': '2022/09/03',
    'notes': [
      'Removed ald patchnotes file and switched to this new system.',
      'Removed file PuzzleLayout because I hav eno clue why it was created.',
      'Removed debugging code from the sudoku puzzle files.',
      'Updated index.js to add the new patchnotes route.',
      'Updated the navItems to include the new patchnotes link.',
      'Added file patchNotes.js to track all teh site changes in.',
      'Added new patch notes section to website.',
      'Added new file GridCopy to get around javascripts lame refrencing crap.',
      'Added file CheckSolutions to make sure our sudoku puzzles only have one solution.',
      'Added the new function CheckSolutions to CreatePuzzle so we can make sure only one solution exists.',
      'Updated resumeData to have a few more entries as I rev it up to release it.'
    ]
  },
  {
    'title': '2022/08/28',
    'notes': [
      'Updated Quote Game to use letters instead of numbers for the cypher.'
    ]
  },
  {
    'title': '2022/08/26',
    'notes': [
      'Changed some colors to make it easier to read Sudoku puzzles.'
    ]
  },
  {
    'title': '2022/08/08',
    'notes': [
      'Changed highlight color to a light yellow',
      'Made box highlighting for currently selected box area work.',
      'Added the ability to win the game.'
    ]
  }
];