import ApacheLogo from "./img/apache.svg";
import BootstrapLogo from "./img/bootstrap.svg";
import CPlusLogo from "./img/cplus.svg";
import CSharpLogo from "./img/csharp.svg";
import CSSLogo from "./img/css.svg";
import CSVLogo from "./img/csv.svg";
import GamingLogo from "./img/gaming.svg";
import GithubLogo from "./img/github.svg";
import HTMLLogo from "./img/html5.svg";
import JavaLogo from "./img/java.svg";
import JQueryLogo from "./img/jquery.svg";
import JSLogo from "./img/js.svg";
import JSXLogo from "./img/jsx.svg";
import LinuxLogo from "./img/linux.svg";
import MaterialUILogo from "./img/mui.svg";
import MySQLLogo from "./img/mysql.svg";
import OracleLogo from "./img/oracle.svg";
import PerlLogo from "./img/perl.svg";
import PhpLogo from "./img/php.svg";
import PYLogo from "./img/py.svg";
import ReactLogo from "./img/react.svg";
import SQLLogo from "./img/sql.svg";
import UnityLogo from "./img/unity.svg"
import WindowsLogo from "./img/windows.svg";
import XMLLogo from "./img/xml.svg";

export const logoData = [
  {
    svg: ApacheLogo,
    title: 'Apache Web Server',
    alt: 'Apache Web Server',
    blurb: 'I have over 25 Years of experience with Apache. I have used it as a standalone server as well as with mod_perl and Tomcat versions of Apache.'
  }, {
    svg: BootstrapLogo,
    title: 'Bootstrap',
    alt: 'Bootstrap',
    blurb: 'I\'ve been using Bootstrap for over 10 years. I started using it in version 2 just before version 3 came out.'
  }, {
    svg: CPlusLogo,
    title: 'C/C++',
    alt: 'C/C++',
    blurb: 'I\'ve been using C++ on and off for over 25 years. I\'m not strong with the language as I have only used it in my spare time.'
  }, {
    svg: CSharpLogo,
    title: 'C#',
    alt: 'CSharp',
    blurb: 'I\'ve coded with C# for over 10 years. I don\'t use it in a web environment but in Unity to make games.'
  }, {
    svg: CSSLogo,
    title: 'CSS',
    alt: 'CSS',
    blurb: 'I have over 25 years of experience coding and using CSS.'
  }, {
    svg: CSVLogo,
    title: 'CSV',
    alt: 'CSV',
    blurb: 'I have over 25 years of experience with CSV files. Both creating them and using them as data to be parsed to feed that data into a database or use it for report creation.'
  }, {
    svg: GamingLogo,
    title: 'Gamer Level 5000',
    alt: 'Gamer',
    blurb: 'No really I have the shirt to prove it!!!'
  }, {
    svg: GithubLogo,
    title: 'GitHub',
    alt: 'GitHub',
    blurb: 'I have over 5 years of experience using Github.'
  }, {
    svg: HTMLLogo,
    title: 'HTML 5',
    alt: 'HTML 5',
    blurb: 'I have over 25 years of exsperience using HTML in all its forms and versions.'
  }, {
    svg: JavaLogo,
    title: 'Java',
    alt: 'Java',
    blurb: 'I\'ve been using Java for over 15 years. It\'s not a language I use every day but know my way around it.'
  }, {
    svg: JQueryLogo,
    title: 'JQuery',
    alt: 'JQuery',
    blurb: 'I have over 15 years of experience with JQuery. Like Bootstrap I have used in many web projects.'
  }, {
    svg: JSLogo,
    title: 'Java Script',
    alt: 'Java Script',
    blurb: 'I have over 25 years of experience with Java Scrip. I use it almost daily in the development of web applications.'
  }, {
    svg: JSXLogo,
    title: 'JSX',
    alt: 'JSX',
    blurb: 'I know JSX as it pertains to React and NodeJS.'
  }, {
    svg: LinuxLogo,
    title: 'Linux',
    alt: 'Linux',
    blurb: 'I have over 25 years of experience with *nix operating systems. I use them daily for my work and run a dedicated dev server of my own for contract work.'
  }, {
    svg: MaterialUILogo,
    title: 'MUI',
    alt: 'MUI',
    blurb: 'I have 3 years of experience with MUI. I started using it when I started playing with react.'
  }, {
    svg: MySQLLogo,
    title: 'MySQL',
    alt: 'MySQL',
    blurb: 'I have 25 years of experience with MySQL and many other SQL databases. Again antoher thing I have used daily in my application development.'
  }, {
    svg: OracleLogo,
    title: 'Oracle',
    alt: 'Oracle',
    blurb: 'I have over 25 years of experience with Oracle. Like MySQL I have used it in many application development projects.'
  }, {
    svg: PerlLogo,
    title: 'Perl',
    alt: 'Perl',
    blurb: 'I have over 25 years of experience with Perl. I have used it for mod_perl application development as well as using it for parsing text files and some cron jobs to clean up and shuffle files around.'
  }, {
    svg: PhpLogo,
    title: 'PHP',
    alt: 'PHP',
    blurb: 'I have over 25 years of experience with PHP. I\'ve used it many times in building out web applications.'
  }, {
    svg: PYLogo,
    title: 'Python',
    alt: 'Python',
    blurb: 'I have 5 years of experience with Python.'
  }, {
    svg: ReactLogo,
    title: 'React',
    alt: 'React',
    blurb: 'I have 5 years of experience with React. It is what this website is coded in.'
  }, {
    svg: SQLLogo,
    title: 'SQL',
    alt: 'SQL',
    blurb: 'I have over 25 years of experience with SQL. I have used this in almost every form it can be used in over my 27 years of professional experience building web applications.'
  }, {
    svg: UnityLogo,
    title: 'Unity',
    alt: 'Unity',
    blurb: 'I have over 10 years of experience working in Unity to create games.'
  }, {
    svg: WindowsLogo,
    title: 'Windows',
    alt: 'Windows',
    blurb: 'Since 95\''
  }, {
    svg: XMLLogo,
    title: 'XML',
    alt: 'XML',
    blurb: 'I have over 20 years of experience with XML.'
  },
];